@tailwind base;
@tailwind components;
@tailwind utilities;

body {
 @apply text-gray-100;
}

input[type="text"],
select {
 @apply border-0 outline-none;
}

.tl {
 @apply justify-start items-baseline text-left;
}
.tc {
 @apply justify-start items-center text-center;
}
.tr {
 @apply justify-start items-end text-right;
}
.ml {
 @apply justify-center items-baseline text-left;
}
.mc {
 @apply justify-center items-center text-center;
}
.mr {
 @apply justify-center items-end text-right;
}
.bl {
 @apply justify-end items-baseline text-left;
}
.bc {
 @apply justify-end items-center text-center;
}
.br {
 @apply justify-end items-end text-right;
}
